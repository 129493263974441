<template>
	<v-sheet height="100%" class="background">
		<v-container class="fill-height justify-center" fluid>
			<v-row align="center" justify="center">
				<v-col cols="12" sm="8" md="7">
					<v-sheet class="with-radius">
						<v-card-text class="pa-0">
							<v-row class="ma-0">
								<v-col cols="12"
									><div class="my-4 mx-auto text-center">
										<div class="text-h4 d-flex justify-center align-center">
											<img src="/static/default/logo-cloud.png" width="60" alt="logo" /><b
												>Franchising Cloud</b
											>
										</div>
										<div class="subtitle-1 mt-3">Crear cuenta</div>
									</div></v-col
								>

								<v-col cols="12" md="6" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
									<v-row align="center" justify="center" class="fill-height ma-0">
										<v-img
											src="/static/illustator/welcome.png"
											lazy-src="/static/illustator/welcome.png"
										/>
									</v-row>
								</v-col>
								<!-- 2 -->
								<v-col cols="12" md="6">
									<v-sheet max-width="380" class="mx-auto" color="transparent">
										<v-form @submit.prevent="$v.$invalid ? null : submit()" ref="form">
											<v-text-field
												color="purple darken-2"
												label="Nombre"
												v-model="form.name"
												required
												:error-messages="fieldErrors('form.name')"
												@blur="$v.form.name.$touch()"
												prepend-inner-icon="person"
												flat
											/>
											<v-text-field
												color="purple darken-2"
												label="Apellido"
												v-model="form.lastname"
												required
												:error-messages="fieldErrors('form.lastname')"
												@blur="$v.form.lastname.$touch()"
												prepend-inner-icon="person"
												flat
											/>
											<v-text-field
												:error-messages="fieldErrors('form.email')"
												@input="$v.form.email.$touch()"
												@blur="$v.form.email.$touch()"
												prepend-inner-icon="email"
												v-model="form.email"
												label="Correo electrónico"
												flat
											/>
											<v-text-field
												:error-messages="fieldErrors('form.password')"
												:append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
												:type="showPwd ? 'text' : 'password'"
												@input="$v.form.password.$touch()"
												@blur="$v.form.password.$touch()"
												prepend-inner-icon="lock"
												v-model="form.password"
												label="Contraseña"
												@click:append="showPwd = !showPwd"
												flat
											/>
											<v-text-field
												type="password"
												label="Confirmación de contraseña"
												v-model="form.repeatPassword"
												:error-messages="fieldErrors('form.repeatPassword')"
												@input="$v.form.repeatPassword.$touch()"
												@blur="$v.form.repeatPassword.$touch()"
												prepend-inner-icon="enhanced_encryption"
												required
												flat
											/>
											<v-checkbox color="primary" v-model="form.agreeToPolicy" required>
												<div slot="label" @click.stop="() => {}">
													Acepto los
													<v-btn-toggle
														class="transparent blue--text"
														@click.native.stop="dialog = !dialog"
														>Términos y Políticas de Privacidad</v-btn-toggle
													>.
												</div>
											</v-checkbox>
											<v-btn block type="submit" color="primary" :disabled="$v.$invalid"
												>Crear
											</v-btn>
											<div class="text-center my-5">
												¿Ya tienes una cuenta?
												<router-link to="/login" class="py-1 no-text-decoration"
													>Entrar</router-link
												>
											</div>
										</v-form>
									</v-sheet>
								</v-col>
							</v-row>
						</v-card-text>
					</v-sheet>
				</v-col>
			</v-row>
		</v-container>
		<v-snackbar v-model="snackbar" absolute top right color="success">
			<span>¡Tu cuenta fue creada satistactoriamente!</span>
			<v-icon dark>check_circle</v-icon>
		</v-snackbar>
	</v-sheet>
</template>

<script>
	// Validations
	import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
	import validationMixin from '@/mixins/validationMixin'

	const defaultForm = {
		name: '',
		lastname: '',
		email: '',
		password: '',
		repeatPassword: '',
		agreeToPolicy: false,
	}
	export default {
		mixins: [ validationMixin ],
		validations: {
			form: {
				name: { required },
				lastname: { required },
				email: { required, email },
				password: { required, minLength: minLength( 6 ) },
				repeatPassword: {
					required,
					sameAsPassword: sameAs( 'password' ),
				},
				agreeToPolicy: { required },
			},
		},
		validationMessages: {
			form: {
				name: {
					required: 'El nombre es requerido.',
				},
				lastname: {
					required: 'El Apellido es requerido.',
				},
				email: {
					required: 'Por favor, introduce un correo electrónico',
					email: 'El correo electrónico debe ser válido',
				},
				password: {
					required: 'Por favor, introduce una contraseña',
					minLength: '6 caracteres como mínimo',
				},
				repeatPassword: {
					sameAsPassword: 'Las contraseñas no coinciden',
				},
			},
		},
		data() {
			return {
				form: Object.assign( {}, defaultForm ),
				showPwd: false,
				snackbar: false,
				dialog: false,
			}
		},
		computed: {
			countryId() {
				if ( localStorage.getItem( 'selectedCountry' ) ) {
					return JSON.parse( localStorage.getItem( 'selectedCountry' ) ).id
				} else {
					return null
				}
			},
		},
		methods: {
			submit() {
				this.snackbar = true
				this.signup()
				this.resetForm()
				this.$v.$reset()
			},

			signup() {
				this.$axios
					.post( 'auth/signup', {
						country_id: this.countryId,
						name: this.form.name,
						type: 0,
						lastname: this.form.lastname,
						email: this.form.email,
						password: this.form.password,
						password_confirmation: this.form.repeatPassword,
					} )
					.then( () => {
						this.$swal( {
							title: '¡Bienvenido!',
							html: 'Creaste tu cuenta satisfactoriamente.<br> Hemos enviado a tu correo electrónico un <strong>código para activar tu cuenta.</strong>',
							showCancelButton: false,
							showConfirmButton: true,
							confirmButtonText: 'Aceptar',
						} )
						setTimeout( () => {
							this.$router.push( {
								name: 'ActiveAccount',
							} )
						}, 2000 )
						this.loading = false
					} )
					.catch( () => {} )
					.finally( () => {} )
			},
			resetForm() {
				this.form = Object.assign( {}, defaultForm )
				this.$refs.form.reset()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.background {
		background-image: url('https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg');
	}
</style>
